import React from "react"

import styled from "styled-components"

import { Row, Col } from "react-bootstrap"

const PricesLayout = () => {
  return (
    <>
      <StyledDivPricingHeadder>
        <Row className="mx-auto">
          <Col>
            <Row className="mx-auto">
              <Col>
                <h2>Free for Small Sellers.</h2>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col>
                <h2>Simple Flexible Pricing Just for You.</h2>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col>
                <h2>Only Pay When You Make Money!</h2>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledDivPricingHeadder>
      <h5 className="text-white h4">
        <Row className="mx-auto">
          {/* <Col sm={12} md={6} lg={3} className="pb-5 pricing-table purple">
            <h3 className="pricing-label">0 - 1000</h3>
            <h2>Monthly Orders</h2>

            <div className="mx-auto price-tag">
              <StyledSymbol className="symbol">$</StyledSymbol>
              <StyledAmount className="amount">FREE</StyledAmount>
            </div>
            <a href="https://app.azszero.com" className="price-button">
              Signup Now
            </a>
          </Col>

          <Col sm={12} md={6} lg={3} className="pb-5 pricing-table  red">
            <div className="pricing-label">1k - 2.5k</div>
            <h2>Monthly Orders</h2>

            <div className="price-tag">
              <StyledSymbol className="symbol">$</StyledSymbol>
              <StyledAmount className="amount">24.99</StyledAmount>
            </div>
            <a href="https://app.azszero.com" className="price-button">
              Signup Now
            </a>
          </Col>

          <Col sm={12} md={6} lg={3} className="pb-5 pricing-table  turquoise">
            <div className="pricing-label">2.5k - 10k</div>
            <h2>Monthly Orders</h2>

            <div className="price-tag">
              <StyledSymbol className="symbol">$</StyledSymbol>
              <StyledAmount className="amount">99.99</StyledAmount>
            </div>
            <a href="https://app.azszero.com" className="price-button">
              Signup Now
            </a>
          </Col> */}

          {/* <Col sm={12} md={6} lg={3} className="pb-5 pricing-table  green">
            <div className="pricing-label">10k - 50k</div>
            <h2>Monthly Orders</h2>

            <div className="price-tag">
              <StyledSymbol className="symbol">$</StyledSymbol>
              <StyledAmount className="amount">499.99</StyledAmount>
              <StyledAfter className="after"></StyledAfter>
            </div>
            <a href="https://app.azszero.com" className="price-button">
              Signup Now
            </a>
          </Col> */}
          <Col sm={12} md={6} lg={3} className="pb-5 ">
            <StyledDivPricingTable className="purple">
              <div className="pricing-label purple-pricing-label">0 - 1000</div>
              <h2>Monthly Orders</h2>
              <div className="price-tag purple-price-tag">
                <StyledSymbol>$</StyledSymbol>
                <StyledAmount>FREE</StyledAmount>
              </div>
              <StyledPriceButton
                href="https://app.azszero.com"
                className="purple-price-button purplea"
              >
                Signup Now
              </StyledPriceButton>
            </StyledDivPricingTable>
          </Col>

          <Col sm={12} md={6} lg={3} className="pb-5 ">
            <StyledDivPricingTable className="red">
              <div className="pricing-label red-pricing-label">1k - 2.5k</div>
              <h2>Monthly Orders</h2>
              <div className="price-tag red-price-tag">
                <StyledSymbol>$</StyledSymbol>
                <StyledAmount>24.99</StyledAmount>
              </div>
              <StyledPriceButton
                href="https://app.azszero.com"
                className="red-price-button reda"
              >
                Signup Now
              </StyledPriceButton>
            </StyledDivPricingTable>
          </Col>

          <Col sm={12} md={6} lg={3} className="pb-5 ">
            <StyledDivPricingTable className="green">
              <div className="pricing-label green-pricing-label">
                2.5k - 10k
              </div>
              <h2>Monthly Orders</h2>
              <div className="price-tag green-price-tag">
                <StyledSymbol>$</StyledSymbol>
                <StyledAmount>99.99</StyledAmount>
              </div>
              <StyledPriceButton
                href="https://app.azszero.com"
                className="green-price-button greena"
              >
                Signup Now
              </StyledPriceButton>
            </StyledDivPricingTable>
          </Col>

          <Col sm={12} md={6} lg={3} className="pb-5 ">
            <StyledDivPricingTable className="turquoise">
              <div className="pricing-label turquoise-pricing-label">
                10k - 50k
              </div>
              <h2>Monthly Orders</h2>
              <div className="price-tag turquoise-price-tag">
                <StyledSymbol>$</StyledSymbol>
                <StyledAmount>499.99</StyledAmount>
              </div>
              <StyledPriceButton
                href="https://app.azszero.com"
                className="turquoise-price-button turquoisea"
              >
                Signup Now
              </StyledPriceButton>
            </StyledDivPricingTable>
          </Col>
        </Row>
      </h5>
    </>
  )
}

const StyledSymbol = styled.span`
  font-size: 12px;
`
const StyledAmount = styled.span`
  letter-spacing: -2px;
  font-size: 58px;
`

const StyledDivPricingHeadder = styled.div`
  border: solid;
  border-radius: 28px;
  text-align: center;
  color: darkgray;
  margin-bottom: 75px;
  background-color: #e2e2e2;
`
const StyledDivPricingTable = styled.div`
  background: #fff;
  box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: 0.3s;
  &:hover.purple {
    box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #757fff30;
  }
  &:hover.red {
    box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #ff5b5b30;
  }
  &:hover.turquoise {
    box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #32637530;
  }
  &:hover.green {
    box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #02960d30;
  }

  & h2 {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .test-one {
    color: green;
  }
  .pricing-label {
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
  }
  .price-tag {
    margin-top: 2rem;
    text-align: center;
    font-weight: 500;
    & .purple-price-tag {
      color: #627afe;
    }
  }

  .purple-pricing-label {
    background: #b2beff;
    color: #4658bb;
  }

  .purple-price-tag {
    color: #627afe;
  }

  .turquoise-pricing-label {
    background: #b9edee;
    color: #216669;
  }

  .turquoise-price-tag {
    color: #216669;
  }

  .red-pricing-label {
    background: #ff5e5e;
    color: white;
  }

  .red-price-tag {
    color: #ff5e5e;
  }

  .green-pricing-label {
    background: #6e9247;
    color: white;
  }

  .green-price-tag {
    color: #6e9247;
  }

  .purple-price-button {
    background: #627afe;
  }

  .turquoise-price-button {
    background: #216669;
  }
  .red-price-button {
    background: #ff5e5e;
  }

  .green-price-button {
    background: #6e9247;
  }
`

const StyledPriceButton = styled.a`
  display: block;
  color: #fff;
  margin-top: 2rem;
  padding: 0.75rem;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  transition: 0.3s;
  text-decoration: none;

  &:hover.purplea {
    text-decoration: none;
    color: white;
    background: #495ab7;
  }
  &:hover.turquoisea {
    text-decoration: none;
    color: white;
    background: #194c4e;
  }
  &:hover.reda {
    text-decoration: none;
    color: white;
    background: #f23c3c;
  }
  &:hover.greena {
    text-decoration: none;
    color: white;
    background: #618040;
  }
`

export default PricesLayout
