import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PricesLayout from "../components/Prices/PricesLayout"
import PricesFaq from "../components/Prices/PricesFaq"
import DivSeperator from "../components/HomePage/DivSeperator"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Container } from "react-bootstrap"

const Prices = () => {
  return (
    <Layout>
      <Seo
        title="AZSzero -- Prices"
        description="Pay-as-you-go azszero pricing model"
      />

      <Container className="px-0 p-4">
        <div className="text-center pb-5" style={{ position: "relative" }}>
          <Styledh1 className="display-4">Pricing</Styledh1>
          <StaticImage
            src="../assets/images/abou-bg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>

        <PricesLayout />
        <DivSeperator />
        <PricesFaq />
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
//

export default Prices
