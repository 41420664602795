import React from "react"
import styled from "styled-components"

const DivSeperator = () => {
  return (
    <Div1Style>
      <Div2Style></Div2Style>
    </Div1Style>
  )
}

const Div1Style = styled.div`
  padding: 2% 0;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 25%;
  margin: 0 0 30px 0;
`

const Div2Style = styled.div`
  border-top-color: gainsboro;
  border-top-width: 1px;
  border-top-style: solid;
`
export default DivSeperator
