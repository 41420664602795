import React from "react"
import { Row, Col } from "react-bootstrap"
import Prices1 from "../Faq/Prices1"
import Prices2 from "../Faq/Prices2"
const PricesFaq = () => {
  return (
    <>
      <Row className="mx-auto pb-5">
        <Col md={12} lg={6} className="pb-5">
          <h2 className="text-center pb-5" style={{ color: "gray" }}>
            PRICING FAQ
          </h2>
          <Row className="mx-auto">
            <Prices1 />
          </Row>
        </Col>

        <Col md={12} lg={6} className="pb-2">
          <h2 className="text-center pb-5" style={{ color: "gray" }}>
            FREE PLAN DETAILS
          </h2>
          <Row className="mx-auto">
            <Prices2 />
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default PricesFaq
